'use strict';

import {
  registerMicroApps,
  runAfterFirstMounted,
  start,
  initGlobalState,
} from 'qiankun';
import appStore from './appStore';
import store from '@/store';

const appContainer = '#microapp-viewport';

export default () => {
  registerMicroApps(
    store.state.common.apps.map(({ name, entry, path }) => ({
      name,
      entry,
      container: appContainer,
      activeRule: `${process.env.VUE_APP_PUBLIC_PATH}/#${path}`,
      props: { prefixPath: path },
    })),
    {
      beforeLoad: [
        app => {
          console.log(
            '[LifeCycle] before load %c%s',
            'color: green;',
            app.name
          );
        },
      ],
      beforeMount: [
        app => {
          console.log(
            '[LifeCycle] before mount %c%s',
            'color: green;',
            app.name
          );
        },
      ],
      afterMount: [
        app => {
          store.commit('common/setLoading', false);
          console.log(
            '[LifeCycle] after mount %c%s',
            'color: green;',
            app.name
          );
        },
      ],
      afterUnmount: [
        app => {
          console.log(
            '[LifeCycle] after unmount %c%s',
            'color: green;',
            app.name
          );
          store.commit('common/setBreadcrumbs', null);
        },
      ],
    }
  );
  runAfterFirstMounted(() => console.log('runAfterFirstMounted'));
  appStore(initGlobalState);
  // start({
  //   sandbox: { experimentalStyleIsolation: true },
  // });
  start();
};
