<template>
  <div class="loading" v-if="$store.state.common.loading">
    <a-spin />
  </div>
  <div v-show="!$store.state.common.loading" id="microapp-viewport" />
</template>

<script>
import microAppStart from '@/qiankun';
import { Spin } from 'ant-design-vue';
export default {
  components: {
    ASpin: Spin,
  },
  setup() {
    if (!window.__qiankun_started__) {
      window.__qiankun_started__ = true;
      microAppStart();
    }
  },
};
</script>

<style lang="less" scoped>
// #microapp-viewport {
//   height: 100%;
// }
.loading {
  height: calc(100% - 40px);
  margin: 20px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
